<template>
  <div class="auth_history">
    <el-table :data="auditHistoryArray">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column property="submittime" label="提交时间" width="160"></el-table-column>
      <el-table-column property="authtime" label="审核时间" width="160"></el-table-column>
      <el-table-column label="审核状态" width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.authresult || scope.row.authresult === 0">
            <el-tag size="mini" :type="auditResult[scope.row.authresult].type" effect="dark">
              {{ auditResult[scope.row.authresult].name }}
            </el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column property="authmemo" label="审核备注" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {common} from "@/apis/index";

export default {
  name: 'DemandAuditHistory',
  props: {
    id: {
      type: Number
    },
    /**
     * 类型 1.需求 2.兼职
     */
    type: {
      type: String,
    }
  },
  data() {
    return {
      auditHistoryArray: [],
      auditResult: [
        {name: '待审核', type: 'info'},
        {name: '已通过', type: 'success'},
        {name: '未通过', type: 'danger'},
        {name: '强制下架', type: 'danger'}
      ]
    }
  },
  watch: {
    id: {
      handler(newValue, oldValue) {
        this.getAuditHistory();
      },
      deep: true
    }
  },
  methods: {
    getAuditHistory() {
      common.loadAuditHistory({
        id: this.id,
        type: this.type,
      }).then(res => {
        const {data = []} = res
        data.forEach(item => {
          if (item.createtime) item.createtime = this.dateToString(new Date(item.createtime), 'yyyy-MM-dd HH:mm:ss');
          if (item.authtime) item.authtime = this.dateToString(new Date(item.authtime), 'yyyy-MM-dd HH:mm:ss');
          if (item.submittime) item.submittime = this.dateToString(new Date(item.submittime), 'yyyy-MM-dd HH:mm:ss');
        })
        this.auditHistoryArray = data;
      })
    }
  },
  computed: {},
  beforeMount() {
    this.getAuditHistory();
  }
}
</script>
<style scoped lang="scss">
.auth_history /deep/ {
  & .el-table {
    font-size: 12px;
  }
}
</style>
